<template>
	<div class="bootstrap-hva">
		<div class="container gutter-hva fix-mobile-width">
			<div>
				<h1>{{checkforTitle}}</h1>
				<div class="content" v-html="checkforText">
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapState } from "vuex";
    export default {
		data: function() {
            return {}
		},
        computed: {
		checkforText() {
			return this.content.intellectueeleigendom ? this.content.intellectueeleigendom.details.Text : null;
            },
					checkforTitle() {
			return this.content.intellectueeleigendom ? this.content.intellectueeleigendom.details.Title : null;
        },
			...mapState(["content"])
		},
        created() {
			this.$store.dispatch("getContent", "intellectueeleigendom");
		}
	};
</script>

<style lang="less" scoped>
h1{
	word-wrap: break-word;
	color: #25167a;
    font-size: 26px;
	text-transform: uppercase;
    letter-spacing: 0.03em;
	font-weight: 400;
    line-height: 1.25;
	padding-left: 240px;
}
div.content{

	padding-left: 240px;
	margin: 5px 0 0;
	font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
}
    @media only screen and (max-width: 767px) {
		h1, div.content{
			padding-left: 0px;
		}

    }
</style>

